import React, { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, Row } from "reactstrap";
import * as Yup from "yup";

import { getErrorMessage } from "../../common/utils/formatter.util";
import { CheckboxFieldInput } from "../../components/common/Input/CheckboxFieldInput";
import { InputField } from "../../components/common/InputField";
import { Loader } from "../../components/common/Loader";
import { useServiceContainer } from "../../hooks/useServiceContainer";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  name: Yup.string().required(),
  surname: Yup.string().required(),
  company: Yup.string(),
  reg_nr: Yup.string(),
  vat_nr: Yup.string(),
  legal_address: Yup.string(),
  actual_address: Yup.string()
});

export const RegisterPage = () => {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });

  const [loader, setLoader] = useState(false);
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const { authService } = useServiceContainer();
  const navigate = useNavigate();
  const { affiliate } = useParams();

  const onSubmitHandler = async (data: any) => {
    try {
      setLoader(true);
      if (affiliate !== undefined) {
        data.affiliate = affiliate;
      }

      await authService.register(data);

      toast.success("Registration was successful, the password was sent to the specified email!");
      navigate("/auth/login");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Card className="overflow-hidden">
        {loader && <Loader />}
        <Row className="g-0">
          <Col lg={12}>
            <div className="p-lg-5 p-4">
              <div>
                <h5>Register account</h5>
                <p className="text-muted">Get your free Alerio account now.</p>
              </div>

              <div className="mt-4 pt-3">
                <form onSubmit={handleSubmit(onSubmitHandler)}>

                  <InputField
                    required
                    {...register("email")}
                    error={errors.email?.message}
                    label="Email"
                    type="email"
                    name="email"
                  />
                  <InputField required {...register("name")} error={errors.name?.message} label="Name" name="name" />
                  <InputField
                    required
                    {...register("surname")}
                    error={errors.surname?.message}
                    label="Surname"
                    name="surname"
                  />

                  <CheckboxFieldInput
                    checked={isLegalPerson}
                    tooltip="If you register as a private person, leave this field empty, if you register as a legal person please fill these fields."
                    onChange={(e) => {
                      setIsLegalPerson(e.target.checked);
                    }}
                    label="Register as company"
                  />

                  {isLegalPerson && (
                    <>
                      <InputField
                        {...register("company")}
                        error={errors.company?.message}
                        label="Company"
                        name="company"
                      />

                      <InputField
                        {...register("reg_nr")}
                        error={errors.reg_nr?.message}
                        label="Registration No."
                        name="reg_nr"
                      />

                      <InputField
                        {...register("vat_nr")}
                        error={errors.vat_nr?.message}
                        label="VAT No."
                        name="vat_nr"
                      />

                      <InputField
                        {...register("legal_address")}
                        error={errors.legal_address?.message}
                        label="Legal address"
                        name="legal_address"
                      />

                      <InputField
                        {...register("actual_address")}
                        error={errors.actual_address?.message}
                        label="Actual address"
                        name="actual_address"
                      />
                    </>
                  )}

                  <div className="mt-4 text-end">
                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                      Register
                    </button>
                  </div>

                  <div className="mt-4 text-center">
                    <p className="mb-0 text-muted">
                      By registering you agree to the Alerio{" "}
                      <a
                        href="https://alerioprint.com/terms-of-service/"
                        target="_blank"
                        className="text-primary fw-semibold text-decoration-underline"
                        rel="noreferrer"
                      >
                        Terms of Use
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="mt-5 text-center">
        <p>
          Already have an account ?{" "}
          <Link to="/auth/login" className="fw-semibold text-decoration-underline">
            {" "}
            Login{" "}
          </Link>{" "}
        </p>
        <p>
          © {new Date().getFullYear()} <b>Alerio</b>
        </p>
      </div>
    </>
  );
};
