import React from "react";

import moment from "moment/moment";
import { Comparator, dateFilter, textFilter } from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";

import { CopyToClipboard } from "../../components/common/CopyToClipboard";
import { ICustomerItemWithUser, IUserItem } from "../interfaces/backoffice.interface";

export const CUSTOMER_TABLE_FILTER_INSTANCES: Record<string, any> = {
  id: null,
  date: null,
  name: null,
  users: null,
  email: null,
  phone: null,
  company_title: null,
  orders_total: null,
  orders_3m: null,
  affiliate_user: null
};

export const CLEAR_CUSTOMER_TABLE_FILTER_VALUES = () => {
  for (const key in CUSTOMER_TABLE_FILTER_INSTANCES) {
    if (CUSTOMER_TABLE_FILTER_INSTANCES[key] && typeof CUSTOMER_TABLE_FILTER_INSTANCES[key] === "function") {
      if (key === "date") {
        CUSTOMER_TABLE_FILTER_INSTANCES[key]({
          comparator: Comparator.EQ,
          date: ""
        });
      } else {
        CUSTOMER_TABLE_FILTER_INSTANCES[key]("");
      }
    }
  }
};

export const CUSTOMER_TABLE_COLUMNS = [
  {
    dataField: "id",
    text: "Id",
    filter: textFilter({
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1",
      getFilter: (filter: any) => {
        CUSTOMER_TABLE_FILTER_INSTANCES.id = filter;
      }
    }),
    sort: true,
    style: {
      minWidth: "70px"
    }
  },
  {
    dataField: "date",
    text: "Creation date",
    filter: dateFilter({
      comparators: undefined,
      comparatorClassName: "d-none",
      dateClassName: "form-control-sm mt-1",
      getFilter: (filter: any) => {
        CUSTOMER_TABLE_FILTER_INSTANCES.date = filter;
      }
    }),
    sort: true,
    formatter: (cellContent: string) => {
      return moment(cellContent).format("DD.MM.YYYY");
    }
  },
  {
    dataField: "name",
    text: "Name",
    style: {
      maxWidth: "180px"
    },
    classes: "text-truncate",
    filter: textFilter({
      getFilter: (filter: any) => {
        CUSTOMER_TABLE_FILTER_INSTANCES.name = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    formatter: (cellContent: string, row: ICustomerItemWithUser) => {
      const content = `${row.name} ${row.surname}`;
      return <CopyToClipboard copyContent={content}>{content}</CopyToClipboard>;
    }
  },
  {
    dataField: "users",
    text: "User",
    style: {
      maxWidth: "200px"
    },
    classes: "text-truncate",
    filter: textFilter({
      getFilter: (filter: any) => {
        CUSTOMER_TABLE_FILTER_INSTANCES.users = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    formatter: (cellContent: IUserItem[]) => {
      const user = cellContent[0];
      return <>{user ? <Link to={`/backoffice/users/${user.id}/edit`}>{user.name}</Link> : "-"}</>;
    }
  },
  {
    dataField: "email",
    text: "Email",
    style: {
      maxWidth: "220px"
    },
    classes: "text-truncate",
    filter: textFilter({
      getFilter: (filter: any) => {
        CUSTOMER_TABLE_FILTER_INSTANCES.email = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    formatter: (cellContent: string) => {
      return <CopyToClipboard copyContent={cellContent}>{cellContent}</CopyToClipboard>;
    },
    sort: true
  },
  {
    dataField: "phone",
    text: "Phone",
    filter: textFilter({
      getFilter: (filter: any) => {
        CUSTOMER_TABLE_FILTER_INSTANCES.phone = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true
  },
  {
    dataField: "company_title",
    text: "Company",
    filter: textFilter({
      getFilter: (filter: any) => {
        CUSTOMER_TABLE_FILTER_INSTANCES.company_title = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true
  },
  {
    dataField: "orders_total",
    text: "Total orders",
    filter: textFilter({
      getFilter: (filter: any) => {
        CUSTOMER_TABLE_FILTER_INSTANCES.orders_total = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true
  },
  {
    dataField: "orders_3m",
    text: "Last 3M orders",
    style: {
      maxWidth: "100px"
    },
    filter: textFilter({
      getFilter: (filter: any) => {
        CUSTOMER_TABLE_FILTER_INSTANCES.orders_3m = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true
  },
  {
    dataField: "will_pay_after",
    text: "Will pay after",
    formatter: (cellContent: number) => {
      return cellContent === 1 ? "Yes" : "No";
    }
  },
  {
    dataField: "affiliate_user",
    text: "Affiliate User",
    style: {
      maxWidth: "200px"
    },
    classes: "text-truncate",
    filter: textFilter({
      getFilter: (filter: any) => {
        CUSTOMER_TABLE_FILTER_INSTANCES.affiliate_user = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: false,
    formatter: (cellContent: string, row: ICustomerItemWithUser) => {
      // if (cellContent.length > 0 && cellContent[0] !== null) {
      //   if ("affiliate_user" in cellContent[0] && cellContent[0].affiliate_user.length > 0) {
      //     const affiliateUser = cellContent[0].affiliate_user[0];
      //     return (
      //       <>
      //         {affiliateUser ? (
      //           <Link to={`/backoffice/users/${affiliateUser.id}/edit`}>{affiliateUser.name}</Link>
      //         ) : (
      //           "-"
      //         )}
      //       </>
      //     );
      //   }
      // }
      if (row.users.length > 0 && row.users[0] !== null) {
        if ("affiliate_user" in row.users[0] && row.users[0].affiliate_user.length > 0) {
          const affiliateUser = row.users[0].affiliate_user[0];
          return (
            <>
              {affiliateUser ? (
                <Link to={`/backoffice/users/${affiliateUser.id}/edit`}>{affiliateUser.name}</Link>
              ) : (
                "-"
              )}
            </>
          );
        }
      }
      return "-";
    }
  }
];
