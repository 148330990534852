import React, { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Card, Col, Form, Input, Label, Row } from "reactstrap";

import { IUserWithRole } from "../../common/interfaces/user.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { Loader } from "../../components/common/Loader";
import useAuth from "../../hooks/useAuth";
import { useServiceContainer } from "../../hooks/useServiceContainer";
import { TokenService } from "../../services/TokenService";
import {setUser, setUserIsIntroductionPassed} from "../../store/user/userSlice";
import {useIntroductionSteps} from "../../hooks/useIntroductionSteps";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const { isAuthenticated } = useAuth();


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { authService } = useServiceContainer();

  const handleSubmit = useCallback(async () => {
    if (!email || !password) {
      setError("Please fill all the fields");
      return;
    }

    setLoader(true);

    try {
      const data: {
        user: IUserWithRole;
        token: string;
      } = await authService.login(email, password);
      TokenService.setAccessToken(data.token as string);

      dispatch(setUser(data.user));

      navigate("/");
    } catch (e) {
      toast.error(getErrorMessage(e));
      console.error(e);
    } finally {
      setLoader(false);
    }
  }, [email, password]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Card className="overflow-hidden">
        {loader && <Loader />}
        <Row className="g-0">
          <Col lg={12}>
            <div className="p-lg-5 p-4">
              <div>
                <h5>Welcome!</h5>
                <p className="text-muted">Sign in to create your custom POD products!</p>
              </div>

              <div className="mt-4 pt-3">
                <Form>
                  {error && <Alert color="danger">{error}</Alert>}
                  <div className="mb-3">
                    <Label for="username" className="fw-semibold">
                      Username
                    </Label>
                    <Input
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="form-control"
                      id="username"
                      placeholder="Enter username"
                    />
                  </div>

                  <div className="mb-3 mb-4">
                    <Label for="userpassword" className="fw-semibold">
                      Password
                    </Label>
                    <Input
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      className="form-control"
                      id="userpassword"
                      placeholder="Enter password"
                    />
                  </div>

                  <div className="row align-items-center">
                    <Col xs={6}>
                      <div className="form-check">
                        <Input type="checkbox" className="form-check-input" id="remember-check" />
                        <Label
                          className="form-check-label"
                          for="remember-check"
                          style={{
                            marginTop: "2px"
                          }}
                        >
                          Remember me
                        </Label>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="text-end">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          style={{
                            fontWeight: "500"
                          }}
                          type="button"
                          onClick={handleSubmit}
                        >
                          Log In
                        </button>
                      </div>
                    </Col>
                  </div>

                  <div className="mt-4">
                    <Link to="/auth/recover" className="text-muted">
                      <i className="mdi mdi-lock me-1" /> Forgot your password?
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="mt-5 text-center">
        <p>
          Don&apos;t have an account ?{" "}
          <Link to="/auth/register" className="fw-semibold text-decoration-underline text-primary">
            {" "}
            Sign up{" "}
          </Link>{" "}
        </p>
        <p>
          © {new Date().getFullYear()} <b>Alerio</b>
        </p>
      </div>
    </>
  );
};
