import React, { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";

import { IShopItem, ShopPlatform } from "../../common/interfaces/integrations.interface";
import { showConfirmationModal } from "../../common/utils/confirm.util";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { useServiceContainer } from "../../hooks/useServiceContainer";
import { Loader } from "../common/Loader";
import { VideoGuide } from "../common/VideoGuide";
import { ConnectedShopItem } from "./ConnectedShopItem";
import { NewShopItem } from "./NewShopItem";

const PLATFORMS = [ShopPlatform.Etsy];

export const ConnectedShopList = () => {
  const { integrationService } = useServiceContainer();

  const [items, setItems] = useState<IShopItem[]>([]);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const fetchList = useCallback(async () => {
    try {
      setLoader(true);
      const res = await integrationService.getConnectedShopList();
      setItems(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  }, []);

  const handleShopDelete = async (item: IShopItem) => {
    try {
      setLoader(true);
      showConfirmationModal({
        title: "Are you sure?",
        message: "Do you want to delete this connection? Please note, all connection data will be lost",
        onConfirm: async () => {
          await integrationService.deleteShopById(item.id);
          await fetchList();
          toast.success("Shop successfully deleted");
        },
        onCancel: () => {}
      });
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleShopConnect = async (platform: ShopPlatform) => {
    try {
      setLoader(true);
      await integrationService.connectShop(platform);
    } catch (e) {
      toast.error(getErrorMessage(e));
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchList().then(() => null);
  }, []);

  return (
    <div className="shop-list">
      {loader && <Loader />}

      <Row>
        <Col xs={6}>
          <div className="d-flex align-items-center mt-2 mb-3">
            <h3 className="mb-0">Connect new shop</h3>
            <VideoGuide title="How to connect Etsy store" videoId="rQk-mXW_vtA" className="ms-auto" />
          </div>
        </Col>
      </Row>

      <div className="row">
        {PLATFORMS.map((platform, idx) => (
          <div className="col-3" key={idx}>
            <NewShopItem platform={platform} onConnectClick={handleShopConnect} />
          </div>
        ))}
      </div>

      <h3 className="mt-4 mb-3">Existing shops</h3>

      <div className="row shop-list__items">
        {items.map((item, idx) => (
          <div className="col-3">
            <ConnectedShopItem
              key={idx}
              item={item}
              onDeleteClick={handleShopDelete}
              actionElement={
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      navigate("/products/create");
                    }}
                  >
                    + Add shop listing
                  </Button>
                </>
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};
