import { IBaseApiResponse } from "../../common/interfaces/api.interface";
import {
  IUser,
  IUserPaymentMethods,
  IUserRole,
  IUserToken,
  IUserWithRole,
  UserRole
} from "../../common/interfaces/user.interface";
import { TokenService } from "../TokenService";
import BaseAPIService from "./BaseApiService";

class AuthApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/api`);
  }

  public async register(data: any): Promise<void> {
    const specifiedData: any = Object.entries(data).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {} as any);
    await this.api.postForm("/register", specifiedData, { headers: { Authorization: "" } });
  }

  public async resetPassword(data: any): Promise<void> {
    await this.api.postForm("/forgot-password", data, { headers: { Authorization: "" } });
  }

  public async confirmPasswordReset(token: string, data: any): Promise<void> {
    await this.api.postForm(
      "/new-password",
      {
        ...data,
        token
      },
      { headers: { Authorization: "" } }
    );
  }

  public async login(
    email: string,
    password: string
  ): Promise<{
    user: IUserWithRole;
    token: string;
  }> {
    const res = await this.api.postForm<
      IBaseApiResponse<
        {
          user: IUser;
        } & IUserRole &
          IUserToken &
          IUserPaymentMethods
      >
    >("/login", { email, password }, { headers: { Authorization: "" } });

    return {
      user: {
        ...res.data.data.user,
        role: res.data.data.role as UserRole[],
        payment_methods: res.data.data.payment_methods,
        is_introduction_passed: res.data.data.user.is_introduction_passed
      },
      token: res.data.data.token.split("|")[1] as string
    };
  }

  public async getUser(): Promise<IUserWithRole> {
    const res = await this.api.get<
      IBaseApiResponse<
        {
          user: IUser;
        } & IUserRole &
          IUserPaymentMethods
      >
    >("/me");

    return {
      ...res.data.data.user,
      is_introduction_passed: res.data.data.user.is_introduction_passed,
      role: res.data.data.role as UserRole[],
      payment_methods: res.data.data.payment_methods
    };
  }

  public async logout(): Promise<void> {
    await this.api.get("/logout");
    TokenService.removeAccessToken();
  }

  public setIntroductionStatus(userId: number): Promise<void> {
    this.api.post("/setIntroductionStatus", {
        'user_id':userId,
        'is_introduction_passed': true
          })
    return Promise.resolve();
  }

}

const instance = new AuthApiService();

export default instance;
