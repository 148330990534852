import React, { useEffect, useMemo, useState } from "react";

import { IProductAssetGroup, IProductAssetItem } from "../../../../common/interfaces/products.interface";
import { CheckboxFieldInput } from "../../Input/CheckboxFieldInput";
import { ListingGroupEditFormItem } from "./ListingGroupEditFormItem";

interface Props {
  currentGroups: IProductAssetGroup[];
  allGroups: IProductAssetGroup[];
  onChange?: (groups: IProductAssetGroup[]) => void;
  modelListId?: number;
  bulkChange?: (modelListId: number, groups: []) => void;
}

export const ListingGroupEditForm: React.FC<Props> = ({
  onChange,
  currentGroups,
  allGroups,
  modelListId,
  bulkChange
}) => {
  const [editedGroups, setEditedGroups] = useState<IProductAssetGroup[]>([]);

  const handleGroupSelect = (group: IProductAssetGroup, newItems: IProductAssetItem[]) => {
    const editedGroup = editedGroups.find((e) => e.id === group.id);
    if (!editedGroup) {
      // if no group found, add new group
      setEditedGroups([...editedGroups, { ...group, items: newItems }]);
    } else {
      // if group found, update items
      setEditedGroups(
        editedGroups.map((e) => {
          if (e.id === group.id) {
            return {
              ...e,
              items: newItems
            };
          }
          return e;
        })
      );
    }
  };

  const nonEmptyGroups = useMemo(() => {
    return editedGroups.filter((e) => e.items.length > 0);
  }, [editedGroups]);

  const isAllGroupsAndAllItemsSelected = useMemo(() => {
    return (
      nonEmptyGroups.length === allGroups.length &&
      allGroups.every((group) => {
        const editedGroup = nonEmptyGroups.find((e) => e.id === group.id);
        if (editedGroup) {
          return editedGroup.items.length === group.items.length;
        }
        return false;
      })
    );
  }, [nonEmptyGroups, allGroups]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setEditedGroups(
        allGroups.map((group) => {
          return {
            ...group,
            items: group.items
          };
        })
      );
    } else {
      setEditedGroups([]);
    }
  };

  const handleClearAll = () => {
    setEditedGroups([]);
  };

  useEffect(() => {
    if (currentGroups) {
      setEditedGroups(currentGroups);
    }
  }, [currentGroups]);

  useEffect(() => {
    // const nonEmptyGroups = editedGroups.filter((e) => e.items.length > 0);
    if (nonEmptyGroups && modelListId === undefined) {
      // @ts-ignore
      onChange(nonEmptyGroups);
    }
    if (modelListId!==undefined && nonEmptyGroups) {
      // @ts-ignore
      bulkChange(modelListId, nonEmptyGroups);
      // console.log("modelListId => ", modelListId);
      // console.log("nonEmptyGroups => ",nonEmptyGroups);
    }
  }, [nonEmptyGroups]);

  return (
    <div className="listing-group-edit-form">
      <div className="listing-group-edit-form__header">
        <CheckboxFieldInput
          checked={isAllGroupsAndAllItemsSelected}
          green
          onChange={handleSelectAll}
          label="Select all"
        />
        <button className="listing-group-edit-form__header__clear-all" onClick={handleClearAll}>
          Clear all
        </button>
      </div>

      {allGroups.map((group, idx) => (
        <ListingGroupEditFormItem
          key={idx}
          group={group}
          selectedItems={editedGroups.find((e) => e.id === group.id)?.items || []}
          onSelect={(newItems) => handleGroupSelect(group, newItems)}
        />
      ))}
    </div>
  );
};
