import React, { useEffect, useMemo, useState } from "react";

import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";

import { IPaginationMeta } from "../../common/interfaces/api.interface";
import {
  IProductListingItem,
  IProductListingItemProduct,
  ProductListingItemInventoryStatus
} from "../../common/interfaces/products.interface";
import { parseQuerySort } from "../../common/utils/filter.util";
import { formatProductType, getImageUrl } from "../../common/utils/formatter.util";
import { calculateModelCount } from "../../common/utils/product-listings.util";
import { Alert } from "../../components/common/Alert";
import { InputGroup } from "../../components/common/InputGroup";
import { Loader } from "../../components/common/Loader";
import { SelectField } from "../../components/common/SelectField";
import { TooltipIcon } from "../../components/common/TooltipIcon";
import { useListingManagementTable } from "../../hooks/listingManagement/useListingManagementTable";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { PRODUCT_TYPES_ACTION_UPDATE_LIST, PRODUCT_TYPES_SET_SELECTED } from "../../store/wizards/productTypesSlice";

export const ProductListingsPage = () => {
  const [search, setSearch] = useState<string>("");

  const {
    productList: { data: productTypes, loader: productTypesLoader },
    selectedProduct
  } = useAppSelector((state) => state.productTypes);

  const dispatch = useAppDispatch();

  const {
    openListingStatusControlModal,
    ListingStatusControlModalComponent,
    items,
    loader,
    onTableChange,
    handleListingDelete,
    pageOptions
  } = useListingManagementTable(search, selectedProduct);

  const productTypesOptions = useMemo(() => {
    return productTypes.all.map((item) => ({
      label: `[${formatProductType(item.type)}] ${item.title}`,
      value: item.id.toString()
    }));
  }, [productTypes]);

  const handleProductTypeChange = (data: any) => {
    const product = productTypes.all.find((e) => e.id.toString() === data.value);
    if (product) {
      dispatch(PRODUCT_TYPES_SET_SELECTED(product));
    }
  };

  const columns = useMemo(() => {
    return [
      {
        dataField: "product",
        text: "Product",
        // filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
        formatter: (cellContent: IProductListingItemProduct, row: IProductListingItem) => {
          const firstPhoto = row.photos?.sort((a, b) => a.order - b.order)[0] ?? null;
          return (
            <div className="product-listings-table__base">
              {firstPhoto && (
                <div className="product-listings-table__base__img">
                  <img src={getImageUrl(firstPhoto.mockup_file)} alt={cellContent.title} />
                </div>
              )}
              <div className="product-listings-table__base__right">
                <div className="product-listings-table__base__right__title">{cellContent.title}</div>
                <div className="product-listings-table__base__right__description">{row.listings.length} listings</div>
                <div className="product-listings-table__base__right__description">
                  {calculateModelCount(row.listings)} phone models
                </div>
              </div>
            </div>
          );
        },
        sort: false
      },
      {
        dataField: "sku",
        isDummyField: true,
        text: "SKU",
        formatter: (cellContent: IProductListingItemProduct, row: IProductListingItem) => {
          return row.photos[0].sku_number;
        },
        sort: false
      },
      {
        dataField: "title",
        isDummyField: true,
        text: "Title",
        // filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
        formatter: (cellContent: IProductListingItemProduct, row: IProductListingItem) => {
          return (
            <div className="product-listings-table__titles">
              {row.listings
                .map((e) => ({
                  title: e.fields.title,
                  id: e.id
                }))
                .map(({ title, id }) => {
                  return (
                    <div className="product-listings-table__titles__item">
                      <TooltipIcon id={`l-title-tt-${id}`} content={title} placement="top-start">
                        <span id={`l-title-tt-${id}`}>{title}</span>
                      </TooltipIcon>
                    </div>
                  );
                })}
            </div>
          );
        }
      },
      {
        dataField: "status",
        text: "Status",
        isDummyField: true,
        // filter: selectFilter({
        //   options: {
        //     0: "Unpublished",
        //     1: "Published"
        //   },
        //   placeholder: " ",
        //   className: "form-control-sm mt-1"
        // }),
        formatter: (cellContent: any, row: IProductListingItem) => {
          const totalCount = row.listings.length;
          const publishedCount = row.listings.filter((e) => e.status).length;
          const allPublished = publishedCount === totalCount;
          const className = allPublished ? "btn-success" : "btn-secondary";
          return (
            <TooltipIcon
              id={`listing-status-control-${row.id}`}
              autohide
              content="To activate or deactivate your listing, press on this button."
            >
              <button
                id={`listing-status-control-${row.id}`}
                className={`btn ${className} btn-sm`}
                type="button"
                onClick={() => {
                  openListingStatusControlModal(row);
                }}
              >
                {allPublished
                  ? totalCount === 1
                    ? "Listing active"
                    : `${publishedCount}/${totalCount} - listings active`
                  : totalCount === 1
                  ? "Listing draft"
                  : `${publishedCount}/${totalCount} - listing active`}
                <span className="ms-1 bx bxs-pencil" />
              </button>
            </TooltipIcon>
          );
        },
        sort: false
      },
      {
        dataField: "status",
        text: "",
        formatter: (cellContent: any, row: IProductListingItem) => {
          return (
            <div className="product-listings-table__actions">
              <div className="product-listings-table__actions__buttons">
                <TooltipIcon
                  id={`listing-edit-control-${row.id}`}
                  autohide
                  content="To edit your listing press on this button"
                >
                  <Link id={`listing-edit-control-${row.id}`} to={`/products/${row.id}`}>
                    <i className="bx bxs-pencil" />
                  </Link>
                </TooltipIcon>
              </div>

              <div className="product-listings-table__actions__menu">
                <UncontrolledDropdown>
                  <DropdownToggle type="button" className="a" color="">
                    <i className="mdi mdi-dots-vertical" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() => {
                        openListingStatusControlModal(row);
                      }}
                    >
                      Listing status control
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        handleListingDelete(row);
                      }}
                    >
                      <span className="text-danger">Delete</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          );
        },
        sort: false
      }
    ];
  }, []);

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  useEffect(() => {
    dispatch(PRODUCT_TYPES_ACTION_UPDATE_LIST());
  }, []);

  return (
    <div className="data-table product-listings-table product-listings-table--filterable">
      {loader && <Loader />}
      <Row>
        <Col xs={6}>
          <div className="d-flex align-items-center">
            <h3 className="mb-0">My Products</h3>
            {/* <Link to="/products/create" className="btn btn-primary btn-sm ms-2" color="primary"> */}
            {/*  Add new product */}
            {/* </Link> */}
            {/* <Link to="/products/create" className="btn btn-primary ms-2" color="primary"> */}
            {/*  Add new product */}
            {/* </Link> */}
          </div>
          <Alert variant="info" className="mt-2 mb-3">
            You can efficiently manage all your listings (products) in this section. Keep track of updates, make edits,
            and ensure all your listings are seamlessly synchronized across various platforms. Simplify your management
            process and keep your information accurate and up-to-date effortlessly.
          </Alert>
          <Link to="/products/create" className="btn btn-primary ms-2" color="primary">
            Add new product
          </Link>
          {/*<Link to="/products/models/edit" className="btn btn-primary ms-2" color="primary">*/}
          {/*  Edit Product models*/}
          {/*</Link>*/}
        </Col>
      </Row>

      <PaginationProvider pagination={paginationFactory(pageOptions)}>
        {({ paginationProps, paginationTableProps }: any) => (
          <ToolkitProvider keyField="id" columns={columns} data={items} search>
            {(toolkitProps: any) => (
              <>
                <Row className="mt-4">
                  <Col xs={3}>
                    <SelectField
                      options={productTypesOptions}
                      placeholder="Select product type"
                      required
                      value={productTypesOptions.find((e) => e.value === selectedProduct?.id.toString()) || null}
                      onChange={handleProductTypeChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup
                      className="wizard-step__search"
                      prependAddon={
                        <span className="input-group-text bg-white">
                          <i className="bx bx-search font-size-18" />
                        </span>
                      }
                      input={(p) => (
                        <input
                          type="text"
                          className="form-control border-start-0"
                          placeholder="Search by sku, title or product"
                          aria-label="Search"
                          value={search}
                          onChange={(e) => {
                            // setSearchInput(e.target.value);
                            setSearch(e.target.value);
                          }}
                          {...p}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        bootstrap4
                        remote
                        onTableChange={onTableChange}
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        // selectRow={selectRowData}
                        filter={filterFactory()}
                        classes="table align-middle table-nowrap table-sm"
                        headerWrapperClasses="thead-light"
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        // expandRow={expandRowData}
                        keyField="id"
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {ListingStatusControlModalComponent}
    </div>
  );
};
