import { useDispatch } from "react-redux";

import { APP_SET_INTRODUCTION_STEP_STATE, IntroductionSteps } from "../store/app/appSlice";
import {useAppSelector} from "../store/store";
import {setUserIsIntroductionPassed} from "../store/user/userSlice";
import {useServiceContainer} from "./useServiceContainer";
import useAuth, {LoggedUser} from "./useAuth";

export const useIntroductionSteps = (key: keyof IntroductionSteps) => {
  const isEnabled = useAppSelector((state) => state.app.introductionSteps[key]);
  const { authService } = useServiceContainer();
  const { user } = useAuth<LoggedUser>();

  const dispatch = useDispatch();

  const setEnabled = (value: boolean) => {
    dispatch(
      APP_SET_INTRODUCTION_STEP_STATE({
        key,
        value
      })
    );
  };

  const handleComplete = () => {
    dispatch(setUserIsIntroductionPassed(true));
    authService.setIntroductionStatus(user.id);
  };

  const handleExit = () => {
    setEnabled(false);
    dispatch(setUserIsIntroductionPassed(true));
  };



  return {
    isEnabled,
    setEnabled,
    handleComplete,
    handleExit
  };
};
