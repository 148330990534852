import React from "react";

import axios from "axios";
import { Col, Row } from "reactstrap";

import { HelpWidget } from "./widgets/HelpWidget";
import { HolidaysWidget } from "./widgets/HolidaysWidget";
import { MessagesWidget } from "./widgets/MessagesWidget";
import { StatisticsWidget } from "./widgets/StatisticsWidget";
import {useIntroductionSteps} from "../../hooks/useIntroductionSteps";
import useAuth, {LoggedUser} from "../../hooks/useAuth";

export const Dashboard = () => {
    const { setEnabled: setLayoutIntroductionEnabled } = useIntroductionSteps("layout");
    const { user } = useAuth<LoggedUser>();
    if(!user.is_introduction_passed){
        setLayoutIntroductionEnabled(true);
    }
  return (
    <div className="p-3">
      <Row>
        <Col xs={12}>
          <MessagesWidget />
        </Col>
        {/* <Col xs={12}> */}
        {/*  <StatisticsWidget /> */}
        {/* </Col> */}
      </Row>
      <Row className="mt-4">
        <Col xs={6}>
          <HolidaysWidget />
        </Col>
        <Col xs={6}>
          <HelpWidget />
        </Col>
      </Row>
    </div>
  );
};
